




































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { AppModule, MessageState } from '@/store/modules/app'
import { Filters, FilterType, SearchModule, SearchResult } from '@/store/modules/search'
import DateFilter from '@/components/DateFilter.vue'
import HierarchieFilter from '@/components/HierarchieFilter.vue'
import LanguageFilter from '@/components/LanguageFilter.vue'
import SortOrderSelector from '@/components/SortOrderSelector.vue'
import { Route } from 'vue-router'
import router from '@/router'
import { BButton } from 'bootstrap-vue'

@Component({
  name: 'SearchResult',
  components: {
    SortOrderSelector,
    DateFilter,
    LanguageFilter,
    HierarchieFilter
  }
})

export default class SearchResults extends Vue {
  private filterVisible = true
  private fullScreen = false
  private windowWidth = 0
  private previewVisible = false
  private sliderWidth = 1
  private allowUndoFilter = false
  private iframeUrl = ''
  public overlayVisible = false
  public overlayFrom = ''
  public overlayTo = ''

  data () {
    return {
      selected: [],
      prettify: function (ts) {
        return new Date(ts).toLocaleDateString('de', {
          year: 'numeric'
        })
      }
    }
  }

  get results () {
    return SearchModule.searchResults
  }

  get pristine () {
    return SearchModule.pristine
  }

  get selectedResult () {
    return SearchModule.selectedResult
  }

  get resultsPending () {
    return SearchModule.resultsPending
  }

  get resultsTotal () {
    return SearchModule.searchTotal
  }

  get aggregations () {
    return SearchModule.aggregations
  }

  get filter () {
    return SearchModule.filters
  }

  get query () {
    return SearchModule.query
  }

  get locale () {
    return AppModule.locale
  }

  get fromState () {
    const from = new Date(this.overlayFrom)
    const fromNumber = from.getTime()
    const to = new Date(this.overlayTo)
    const toNumber = to.getTime()
    return !isNaN(fromNumber)
  }

  get toState () {
    const from = new Date(this.overlayFrom)
    const fromNumber = from.getTime()
    const to = new Date(this.overlayTo)
    const toNumber = to.getTime()
    return !isNaN(toNumber)
  }

  get rangeState () {
    const from = new Date(this.overlayFrom)
    const fromNumber = from.getTime()
    const to = new Date(this.overlayTo)
    const toNumber = to.getTime()
    if (!isNaN(toNumber) && !isNaN(fromNumber)) {
      return fromNumber - 1 < toNumber
    } else {
      return true
    }
  }

  @Watch('locale')
  public onLocaleChanged () {
    if (SearchModule.document !== '') {
      SearchModule.SetDocumentResult()
    } else {
      SearchModule.SetResults()
    }
  }

  @Watch('results')
  public onResultsChanged () {
    const selectedId = this.$route.query.selected
    const preview = this.$route.query.preview
    if (selectedId && !('id' in this.selectedResult)) {
      const oldSelectedResult = this.getResultbyId(selectedId.toString())
      if (oldSelectedResult) {
        SearchModule.Select(oldSelectedResult)
        if (!this.previewVisible && preview) {
          this.onOpenPreview()
        }
      }
    }
  }

  @Watch('selectedResult')
  public onSelectedResultChanged (selectedResult: SearchResult) {
    if (!('id' in selectedResult)) {
      this.previewVisible = false
      this.fullScreen = false
      return
    }
    let iFrame = document.getElementById('result-iframe')
    if (this.windowWidth <= 1024) {
      iFrame = document.getElementById('mobile-result-iframe')
    }
    const iFrameParent = document.getElementById('outer-pdf')
    if (iFrame && iFrameParent && selectedResult.url) {
      const newUrl = selectedResult.url + (this.fullScreen ? '' : '#view=FitH')
      const url = iFrame.getAttribute('src')
      if (url !== newUrl) {
        iFrame.remove()
        iFrame.setAttribute('src', newUrl)
        this.iframeUrl = newUrl
        iFrameParent.append(iFrame)
      }
    }
  }

  @Watch('filter')
  public onFilterChanged (filters: Filters) {
    this.allowUndoFilter = Object.keys(filters).length > 0
  }

  @Watch('$route', { immediate: true, deep: true })
  onRouteChange (from: Route, to: Route) {
    const name = this.$route.name
    if (name === 'View') {
      if ('url' in this.selectedResult) {
        if (!this.previewVisible) {
          this.previewVisible = true
          this.fullScreen = true
        }
        const newUrl = this.selectedResult.url + (this.fullScreen ? '' : '#view=FitH')
        this.iframeUrl = newUrl
      }
    }
    if (from !== to && this.overlayVisible) {
      this.onCancelOverlay()
    }
  }

  @Watch('query')
  public onQueryChange () {
    const preview = this.$route.query.preview
    const fullScreen = this.$route.query.fullScreen
    if (!preview && !fullScreen) {
      this.previewVisible = false
      this.fullScreen = false
    }
  }

  created () {
    this.fullScreen = SearchModule.document !== '' && Object.prototype.hasOwnProperty.call(SearchModule.selectedResult, 'id')
    if (this.fullScreen) {
      this.previewVisible = true
    }
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('popstate', this.handlePopState)
    this.handleResize()
    this.getFilterInnerWidth()
  }

  mounted () {
    this.allowUndoFilter = Object.keys(this.filter).length > 0
  }

  destroyed () {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('popstate', this.handlePopState)
  }

  handleResize () {
    this.getFilterInnerWidth()
    this.windowWidth = window.innerWidth
    this.filterVisible = this.windowWidth > 1024
  }

  handleScroll () {
    const searchResultsDiv = document.getElementById('results')
    if (!SearchModule.allResultsLoaded && !SearchModule.resultsPending && searchResultsDiv !== null) {
      if (searchResultsDiv.scrollTop + searchResultsDiv.clientHeight >= searchResultsDiv.scrollHeight - 10) {
        this.getMoreResults()
      }
    }
  }

  handlePopState () {
    const query = this.$route.query.query
    const selectedId = this.$route.query.selected
    const preview = this.$route.query.preview
    const fullScreen = this.$route.query.fullScreen
    const name = this.$route.name
    if (query && query !== this.query && ((fullScreen && this.windowWidth > 534) || (preview && this.windowWidth <= 534))) {
      this.fullScreen = true
      this.previewVisible = true
      SearchModule.ResetQuery(query.toString())
      return
    } else if (query && query !== this.query) {
      SearchModule.ResetQuery(query.toString())
      return
    }
    if ('id' in this.selectedResult) {
      if (this.selectedResult.id !== selectedId) {
        if (selectedId) {
          const oldSelectedResult = this.getResultbyId(selectedId.toString())
          if (oldSelectedResult) {
            SearchModule.Select(oldSelectedResult)
          }
        } else {
          SearchModule.Select()
        }
      }
    } else if (preview && !this.previewVisible && this.windowWidth <= 534) {
      const oldSelectedResult = this.getResultbyId(selectedId.toString())
      if (oldSelectedResult) {
        SearchModule.Select(oldSelectedResult)
        this.previewVisible = true
        this.fullScreen = true
        return
      }
    }
    if (preview && !this.previewVisible) {
      this.previewVisible = true
      if (this.windowWidth <= 534) {
        this.fullScreen = true
      }
      return
    }
    if (preview === undefined && this.previewVisible) {
      this.previewVisible = false
      if (this.windowWidth <= 534) {
        this.fullScreen = false
      }
    }
    if (fullScreen === undefined && this.fullScreen) {
      this.fullScreen = false
      if (this.windowWidth <= 534) {
        this.previewVisible = false
      }
    }
    if (fullScreen === 'true' && !this.fullScreen) {
      this.fullScreen = true
    }
  }

  getResultbyId (id: string): SearchResult | undefined {
    for (let i = 0; i < this.results.length; i++) {
      if (this.results[i].id === id) {
        return this.results[i]
      }
    }
    return undefined
  }

  public onCancelOverlay (): void {
    this.overlayVisible = false
    this.removeGuardListeners()
  }

  public onConfirmOverlay (): void {
    if (this.fromState && this.toState && this.rangeState) {
      const from = new Date(this.overlayFrom)
      const fromNumber = from.getTime()
      const to = new Date(this.overlayTo)
      const toNumber = to.getTime();
      (this.$refs.dateFilter as DateFilter).handleRangeChange(fromNumber, toNumber)
      this.overlayVisible = false
      this.removeGuardListeners()
    }
  }

  public onShowOverlay (): void {
    this.overlayVisible = true
    this.initOverlayDates()
    this.$nextTick(() => {
      const focusGuard1 = this.$refs.focusguardFirst as HTMLElement
      const focusGuard2 = this.$refs.focusguardSecond as HTMLElement
      if (focusGuard1) {
        focusGuard1.addEventListener('focus', this.focusOnLast)
      }
      if (focusGuard2) {
        focusGuard2.addEventListener('focus', this.focusOnFirst)
      }
      (this.$refs.confirmBtn as BButton).focus()
    })
  }

  public removeGuardListeners () {
    const focusGuard1 = this.$refs.focusguardFirst as HTMLElement
    const focusGuard2 = this.$refs.focusguardSecond as HTMLElement
    if (focusGuard1) {
      focusGuard1.removeEventListener('focus', this.focusOnLast)
    }
    if (focusGuard2) {
      focusGuard2.removeEventListener('focus', this.focusOnFirst)
    }
  }

  public focusOnLast () {
    (this.$refs.confirmBtn as BButton).focus()
  }

  public focusOnFirst () {
    (this.$refs.fromField as HTMLInputElement).focus()
  }

  public onToggleFilter (): void {
    this.filterVisible = !this.filterVisible
  }

  public onFullScreen (): void{
    const name = this.$route.name
    if (name !== 'View') {
      if (!this.fullScreen) {
        if ('id' in SearchModule.selectedResult) {
          SearchModule.SetFullScreen('true')
          this.fullScreen = true
        }
      } else {
        if (this.windowWidth <= 534) {
          this.previewVisible = false
          SearchModule.SetPreview(false)
        } else {
          SearchModule.SetFullScreen('')
        }
        this.fullScreen = false
      }
    } else if (this.fullScreen) {
      router.push({ name: 'Home' })
    }
  }

  public onPrint (): void{
    // TODO
  }

  public onOpenSource (): void{
    // TODO
  }

  public onNewTab (): void{
    // if (!this.fullScreen) {
    if ('id' in SearchModule.selectedResult) {
      window.open('/view/' + SearchModule.selectedResult.id, '_blank')
      self.focus()
    }
    // }
  }

  public onOpenPreview (): void{
    this.previewVisible = true
    if (this.windowWidth <= 534) {
      this.fullScreen = true
    }
  }

  public get showMessage () {
    return AppModule.showMessage === MessageState.VISIBLE
  }

  public onClosePreview (): void{
    this.previewVisible = false
    SearchModule.SetPreview(false)
    if (this.fullScreen) {
      this.fullScreen = false
      if (this.windowWidth > 534) {
        this.previewVisible = true
      }
    }
  }

  public initOverlayDates (): void {
    const minEdatum = Number(SearchModule.aggregations.min_edatum[0].key)
    const maxEdatum = Number(SearchModule.aggregations.max_edatum[0].key)
    if (!Object.prototype.hasOwnProperty.call(this.filter, 'edatum')) {
      if (minEdatum) {
        this.overlayFrom = this.dateToString(minEdatum)
      }
      if (maxEdatum) {
        this.overlayTo = this.dateToString(maxEdatum)
      }
    } else {
      const from = this.filter.edatum.payload.from
      const to = this.filter.edatum.payload.to
      if (from) {
        this.overlayFrom = this.dateToString(from)
      } else if (minEdatum) {
        this.overlayFrom = this.dateToString(minEdatum)
      }
      if (to) {
        this.overlayTo = this.dateToString(to)
      } else if (maxEdatum) {
        this.overlayTo = this.dateToString(maxEdatum)
      }
    }
  }

  public dateToString (date: Date | number): string {
    if (date instanceof Date) {
      return date.toISOString().split('T')[0]
    } else {
      const d = new Date(date)
      return d.toISOString().split('T')[0]
    }
  }

  public getImgUrl (canton: string) {
    return require('../assets/cantons/' + canton + '.png')
  }

  public onSelectResult (result: SearchResult): void {
    SearchModule.Select(result)
  }

  public isSelected (result: SearchResult): boolean {
    return SearchModule.selectedResult === result
  }

  public getMobileDocUrl (url: string) {
    return 'https://drive.google.com/viewerng/viewer?embedded=true&url=' + url
  }

  public getMoreResults () {
    SearchModule.SetMoreResults()
  }

  public getFilterInnerWidth () {
    if (this.windowWidth > 1024) {
      this.sliderWidth = 258 - 16
    } else if (this.windowWidth > 534) {
      this.sliderWidth = 258
    } else {
      this.sliderWidth = (this.windowWidth - 90)
    }
  }

  public undoFilter () {
    SearchModule.SetFilters([])
  }

  public undoDateFilter () {
    SearchModule.RemoveFilter(FilterType.DATE)
  }

  public undoLanguageFilter () {
    SearchModule.RemoveFilter(FilterType.LANGUAGE)
  }

  public undoHierarchieFilter () {
    SearchModule.RemoveFilter(FilterType.HIERARCHY)
  }

  public dateFilterEmpty () {
    return !(Object.keys(this.filter).includes('edatum'))
  }

  public languageFilterEmpty () {
    return !(Object.keys(this.filter).includes('language'))
  }

  public hierarchieFilterEmpty () {
    return !(Object.keys(this.filter).includes('hierarchie'))
  }

  public onToggleAbstract (id: string) {
    const textDiv = document.getElementById(id)
    const button = document.getElementById(('button-' + id))
    if (textDiv !== null && button !== null) {
      if (textDiv.style.whiteSpace !== 'normal') {
        textDiv.style.whiteSpace = 'normal'
      } else {
        textDiv.style.whiteSpace = 'nowrap'
      }
      if (button.style.transform !== 'rotate(90deg)') {
        button.style.transform = 'rotate(90deg)'
      } else {
        button.style.transform = 'rotate(0deg)'
      }
    }
  }
}
